<script>
import { inject, watchEffect } from "vue";
import feather from "feather-icons";
import AppHeader from "./components/shared/AppHeader";


export default {
  components: {
    AppHeader,
  },
  setup() {
    const themeState = inject("themeState");

    // Watch for theme changes and apply changes reactively
    watchEffect(() => {
      // Toggle dark class on <html> for Tailwind dark mode
      document.documentElement.classList.toggle("dark", themeState.theme === "dark");

      // Set body background color based on theme
      document.body.classList.toggle("bg-primary-dark", themeState.theme === "dark");
      document.body.classList.toggle("bg-primary-light", themeState.theme === "light");

      // Refresh Feather icons on theme change
      feather.replace();
    });

    return { themeState };
  },
};
</script>

<template>
  <div class="pt-0">
    <AppHeader />
    <router-view v-slot="{ Component, route }">
      <transition name="fade">
        <component :is="Component" :key="route.path" />
      </transition>
    </router-view>
    <back-to-top
      visibleoffset="500"
      right="30px"
      bottom="20px"
      class="shadow-lg"
    >
      <i data-feather="chevron-up"></i>
    </back-to-top>

  </div>
</template>

<style>
.vue-back-to-top {
  @apply p-2 bg-accent opacity-90 hover:opacity-100 hover:bg-accent text-white transition
        duration-500
        ease-in-out
        transform
        hover:-translate-y-1 hover:scale-110;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
}

.fade-enter-active {
  animation: coming 0.4s;
  animation-delay: 0.2s;
  opacity: 0;
}

.fade-leave-active {
  animation: going 0.4s;
}

@keyframes going {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-10px);
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
</style>
