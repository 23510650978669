<script setup>
import { ref, computed, onMounted } from "vue";
import feather from "feather-icons";
import BlogSingle from "../blogs/BlogSingle.vue";
import blogsData from "../../data/blogs";
import SectionTitle from "../reusable/SectionTitle.vue";

const blogs = ref(blogsData);
const selectedCategory = ref("");
const searchBlog = ref("");

const filteredBlogs = computed(() => {
  if (selectedCategory.value) {
    return filterBlogsByCategory();
  } else if (searchBlog.value) {
    return filterBlogsBySearch();
  }
  return blogs.value.sort((a, b) => b.id - a.id).slice(0, 4);
});

onMounted(() => {
  feather.replace();
});
</script>

<template>
  <section class="pt-10 sm:pt-14">
    <SectionTitle title="Recent Blogs" desc=" Recent blog that I published" />

    <!-- Blogs grid -->
    <div
      class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2  mt-6 gap-6 md:gap:10"
    >
      <BlogSingle v-for="blog in filteredBlogs" :key="blog.id" :blog="blog" />
    </div>

    <div class="mt-5 sm:mt-10 flex justify-center">
      <router-link
        to="/blogs"
        class="flex justify-center items-center px-5 text-center mb-6 sm:mb-0 text-lg font-bold border border-accent py-2.5 sm:py-3 shadow-lg rounded-lg bg-accent focus:ring-1 focus:ring-white hover:bg-white hover:border-white text-white hover:text-accent duration-500"
        aria-label="Download Resume"
      >
        <span class="text-sm sm:text-lg duration-100">More Blogs</span>

        <i
          data-feather="corner-right-up"
          class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
        ></i
      ></router-link>
    </div>
  </section>
</template>

<style lang="scss" scoped></style>
