<script>
import { inject, computed, watchEffect } from "vue";
import feather from "feather-icons";

export default {
  setup() {
    const themeState = inject("themeState");



    // Watch for changes and refresh Feather icons
    watchEffect(() => {
      feather.replace();
    });

    return {
      themeState,
      toggleTheme() {
        themeState.toggleTheme();
      },
    };
  },
};
</script>

<template>
  <a href="#" @click.prevent="toggleTheme" aria-label="Theme Switcher">
    <img
      v-if="themeState.theme === 'light'"
      src="@/assets/images/moon.svg"
      class="w-7 lg:w-8 p-1.5"
      alt="Dark Logo"
    />
    <img
      v-else
      src="@/assets/images/sun.svg"
      class="w-7 lg:w-8 p-1.5"
      alt="Light Logo"
    />
  </a>
</template>
