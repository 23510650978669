import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/app.css";
import "animate.css";
import "../node_modules/nprogress/nprogress.css";
import BackToTop from "vue-backtotop";
import feather from "feather-icons";

const themeState = reactive({
  theme: localStorage.getItem("theme") || "light",
  toggleTheme() {
    this.theme = this.theme === "dark" ? "light" : "dark";
    localStorage.setItem("theme", this.theme);

    // // Apply or remove the dark class on <html> for Tailwind dark mode
    document.documentElement.classList.toggle("dark", this.theme === "dark");

    // // Set background color based on theme
    document.body.classList.toggle("bg-primary-dark", this.theme === "dark");
    document.body.classList.toggle("bg-primary-light", this.theme === "light");

    // Refresh Feather icons after theme change
    feather.replace();
  },
});

// Apply initial theme settings on the <html> and <body> elements
document.documentElement.classList.toggle("dark", themeState.theme === "dark");
document.body.classList.add(
  themeState.theme === "dark" ? "bg-primary-dark" : "bg-primary-light"
);

const app = createApp(App);
app.provide("themeState", themeState);
app.use(router).use(BackToTop).mount("#app");
