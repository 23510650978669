<script setup>
import { ref, computed, onMounted } from "vue";
import feather from "feather-icons";
import ProjectSingle from "../projects/ProjectSingle.vue";
import projectsData from "../../data/projects";
import SectionTitle from "../reusable/SectionTitle.vue";

const projects = ref(projectsData);
const selectedCategory = ref("");
const searchProject = ref("");

const filteredProjects = computed(() => {
  if (selectedCategory.value) {
    return filterProjectsByCategory();
  } else if (searchProject.value) {
    return filterProjectsBySearch();
  }

  return projects.value.sort((a, b) => b.id - a.id).slice(0, 6);
});

onMounted(() => {
  feather.replace();
});
</script>
<template>
  <section class="pt-10 sm:pt-14">
    <!-- Projects grid title -->
    <SectionTitle
      title="Featured Projects"
      desc="Some of the projects that I have worked on"
    />

    <!-- Projects grid -->
    <div
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 gap-5 md:gap-10"
    >
      <ProjectSingle
        v-for="project in filteredProjects"
        :key="project.id"
        :project="project"
      />
    </div>

    <div class="mt-5 sm:mt-10 flex justify-center">
      <router-link
        to="/projects"
        class="flex justify-center items-center px-5 text-center mb-6 sm:mb-0 text-lg font-bold border border-accent py-2.5 sm:py-3 shadow-lg rounded-lg bg-accent focus:ring-1 focus:ring-white hover:bg-white hover:border-white text-white hover:text-accent duration-500"
        aria-label="Download Resume"
      >
        <span class="text-sm sm:text-lg duration-100">More Projects</span>

        <i
          data-feather="corner-right-up"
          class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
        ></i
      ></router-link>
    </div>
  </section>
</template>

<style lang="scss" scoped></style>
