<script>
import { inject, watchEffect } from "vue";
import feather from "feather-icons";
import ThemeSwitcher from "../ThemeSwitcher";
import AppHeaderLinks from "./AppHeaderLinks.vue";

export default {
  components: {
    ThemeSwitcher,
    AppHeaderLinks,
  },

  data() {
    return {
      isOpen: false,
      theme: "dark",
      modal: false,
    };
  },

  setup() {
    const themeState = inject("themeState");

    // Watch for theme changes and update Feather icons reactively
    watchEffect(() => {
      feather.replace();
    });

    return { themeState };
  },
};
</script>



<template>
  <nav id="nav" class="fixed mx-auto px-1 container top-0 left-0 right-0 z-50">
    <!-- Header start -->
    <div
      class="w-full shadow-[0_0px_5px_2px_rgba(0,0,0,0.05)] bg-[rgba(255,255,255,0.5)] dark:bg-[rgba(16,45,68,0.5)] rounded-none md:rounded-lg px-5 py-2 z-10 backdrop-blur-[5px] md:flex md:justify-between md:items-center my-0 sm:my-6"
    >
      <!-- Header menu links and small screen hamburger menu -->
      <div class="flex justify-between items-center px-4 sm:px-0">
        <!-- Header logos -->
        <div>
          <router-link to="/"
            >
            <img
            v-if="themeState.theme === 'light'"
              src="@/assets/images/logo-dark.png"
              class="w-12 py-2"
              alt="Dark Logo"
            />
            <img
              v-else
              src="@/assets/images/logo-light.png"
              class="w-12 py-2"
              alt="Light Logo"
            />
          </router-link>
        </div>

        <!-- Theme switcher small screen -->

        <!-- Small screen hamburger menu -->
        <div class="md:hidden flex">
          <theme-switcher
            class="block md:hidden bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2 py-1.5 rounded-lg mr-4"
          />

          <button
            @click="isOpen = !isOpen"
            type="button"
            class="focus:outline-none"
            aria-label="Hamburger Menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="h-9 w-9 fill-current text-secondary-dark dark:text-ternary-light"
            >
              <path
                v-if="isOpen"
                fill-rule="evenodd"
                d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                clip-rule="evenodd"
              ></path>
              <path
                v-if="!isOpen"
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Header links -->
      <AppHeaderLinks
        :showModal="showModal"
        :is-open="isOpen"
        @update:is-open="isOpen = $event"
      />

      <!-- Header right section buttons -->
      <div
        class="hidden md:flex justify-between items-center flex-col md:flex-row"
      >
        <!-- Theme switcher large screen -->
        <theme-switcher
          class="ml-8 bg-primary-light dark:bg-ternary-dark px-3 py-2 shadow-sm rounded-xl cursor-pointer"
        />
      </div>
    </div>
  </nav>
</template>


<!-- 

<template>
  <nav id="nav" class="fixed mx-auto px-1 container top-0 left-0 right-0 z-50">
    <div
      class="w-full shadow-[0_0px_5px_2px_rgba(0,0,0,0.05)] bg-[rgba(255,255,255,0.5)] dark:bg-[rgba(16,45,68,0.5)] rounded-none md:rounded-lg px-5 py-2 z-10 backdrop-blur-[5px] md:flex md:justify-between md:items-center my-0 sm:my-6"
    >
      <div class="flex justify-between items-center px-4 sm:px-0">
        <router-link to="/">
          <img
            v-if="themeState.theme === 'light'"
            src="@/assets/images/logo-dark.png"
            class="w-12 py-2"
            alt="Dark Logo"
          />
          <img
            v-else
            src="@/assets/images/logo-light.png"
            class="w-12"
            alt="Light Logo"
          />
        </router-link>

        <div class="md:hidden flex">
          <theme-switcher class="block md:hidden bg-ternary-light dark:bg-ternary-dark hover:bg-hover-light dark:hover:bg-hover-dark hover:shadow-sm px-2.5 py-2 rounded-lg mr-4" />
        </div>
      </div>

      <AppHeaderLinks :is-open="isOpen" @update:is-open="isOpen = $event" />

      <div
        class="hidden md:flex justify-between items-center flex-col md:flex-row">
        <theme-switcher class="ml-8 bg-primary-light dark:bg-ternary-dark px-3 py-2 shadow-sm rounded-xl cursor-pointer" />
      </div>
    </div>
  </nav>
</template> -->
