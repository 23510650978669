<script>
import { Icon } from "@iconify/vue";
import { inject, computed, toRefs } from "vue";

export default {
  components: {
    Icon,
  },
  props: ["project"],
  setup(props) {
    // Destructure props for reactivity
    const { project } = toRefs(props);

    // Inject the reactive theme state
    const themeState = inject("themeState");

    // Computed property for background color based on theme
    const backgroundColor = computed(() => {
      if (themeState.theme === "light") {
        const hex = project.value.color.replace("#", "");
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return `rgba(${r}, ${g}, ${b}, 0.05)`; // Light theme with low opacity
      } else {
        return "#1E3851"; // Dark theme background color
      }
    });

    return {
      themeState,
      backgroundColor,
    };
  },
};
</script>

<template>
  <router-link
    :to="`/project/${project.permalink}`"
    :style="`background-color: ${backgroundColor}; border-color: ${project.color};`"
    class="rounded-xl shadow-lg hover:shadow-xl hover:scale-[1.01] duration-200 cursor-pointer mb-10 border-b-4 sm:mb-0 dark:bg-ternary-dark"
    aria-label="Single Project">
    <div class="relative">
      <p
        class="absolute right-2 top-2 z-20 rounded-lg p-2 text-white font-bold text-sm duration-300 shadow-md bg-ternary-light dark:bg-ternary-dark">
        <span v-if="project.category == 'WordPress Customization'">
          <Icon icon="skill-icons:wordpress" class="text-2xl" />
        </span>

        <span v-if="project.category == 'WordPress Development'">
          <Icon icon="skill-icons:wordpress" class="text-2xl" />
        </span>

        <span v-if="project.category == 'HTML Template'">
          <Icon icon="skill-icons:html" class="text-2xl" />
        </span>

        <span v-if="project.category == 'Raw PHP'">
          <Icon icon="skill-icons:php-light" class="text-2xl" />
        </span>

        <span v-if="project.category == 'React JS'">
          <Icon icon="logos:react" class="text-2xl" />
        </span>

        <span v-if="project.category == 'Vue JS'">
          <Icon icon="vscode-icons:file-type-vue" class="text-2xl" />
        </span>

        <span v-if="project.category == 'Laravel'">
          <Icon icon="skill-icons:laravel-light" class="text-2xl" />
        </span>

        <span v-if="project.category == 'Laravel + React'">
          <span class="flex">
            <Icon icon="skill-icons:laravel-light" class="text-2xl" />
            <span class="text-ternary-dark dark:text-white text-xl px-2"
              >+</span
            >
            <Icon icon="logos:react" class="text-2xl" />
          </span>
        </span>
      </p>

      <img
        :src="project.img"
        :alt="project.title"
        class="rounded-t-xl border-none brightness-90 hover:brightness-100 duration-150" />
    </div>
    <div class="relative text-center px-4 py-6">
      <h1
        class="text-lg lg:text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-5">
        {{ project.title }}
      </h1>

      <span
        v-for="tag in project.tags.slice(0, 5)"
        :key="tag"
        class="relative inline-block font-medium text-xs text-ternary-dark border border-gray-300 dark:border-gray-600 py-1 px-2 m-1 dark:text-ternary-light"
        >{{ tag }}</span
      >

      <div class="flex justify-around flex-shrink-0 flex-wrap text-center mt-5">
        <a
          v-if="project.live_link"
          target="_blank"
          :href="project.live_link"
          @click.stop="true"
          class="flex justify-center flex-shrink-0 flex-wrap items-center text-center text-sm text-ternary-dark dark:text-secondary-light m-1 group">
          <Icon
            icon="fluent:live-24-regular"
            class="text-lg mr-1 group-hover:text-accent duration-300" />
          <span
            class="text-md font-semibold duration-300 group-hover:text-accent"
            >Live Link</span
          >
          <Icon
            icon="icon-park-solid:right-one"
            class="text-lg group-hover:text-accent duration-300" />
        </a>

        <a
          v-if="project.github_link"
          target="_blank"
          :href="project.github_link"
          @click.stop="true"
          class="flex justify-center flex-shrink-0 flex-wrap items-center text-center text-sm text-ternary-dark dark:text-secondary-light m-1 group">
          <Icon
            icon="uil:github"
            class="text-lg mt-[-2px] mr-1 group-hover:text-accent" />
          <span class="text-md font-semibold group-hover:text-accent"
            >Source Code</span
          >
          <Icon
            icon="icon-park-solid:right-one"
            class="text-lg group-hover:text-accent duration-300" />
        </a>
      </div>
    </div>
  </router-link>
</template>
