<template>
  <section
    class="flex flex-col-reverse justify-center md:justify-between items-center md:flex-row mt-12 sm:mt-10 py-10 md:py-20 gap-5 md:gap-0">
    <!-- Banner left contents -->
    <div class="w-full md:w-1/2">
      <h1
        class="font-bold text-3xl md:text-4xl xl:text-5xl mb-2 text-center md:text-left text-ternary-dark dark:text-primary-light">
        <span
          v-for="(letter, index) in hiArray"
          :key="'hi' + index"
          class="animate-[bounceIn_1s_.5s] hover:animate-[rubberBand_1s] inline-block hover:text-accent"
          >{{ letter }}</span
        >
      </h1>

      <h1
        class="font-bold text-3xl md:text-4xl xl:text-6xl mb-2 text-center md:text-left text-ternary-dark dark:text-primary-light uppercase">
        <span
          v-for="(letter, index) in nameArray"
          :key="'name' + index"
          class="animate-[bounceIn_1s_1s] hover:animate-[rubberBand_1s] inline-block hover:text-accent font-bold"
          v-html="letter.toString()"></span>
      </h1>

      <h2
        class="text-2xl md:text-3xl xl:text-4xl mb-2 text-center md:text-left text-ternary-dark dark:text-primary-light">
        <span
          v-for="(letter, index) in jobArray"
          :key="'job' + index"
          class="animate-[bounceIn_1s_1.5s] hover:animate-[rubberBand_1s] inline-block hover:text-accent"
          v-html="letter.toString()"></span>
      </h2>

      <p
        class="animate-[bounceIn_1s_2s] mt-5 text-lg sm:text-xl xl:text-2xl text-center md:text-left leading-none text-blueGray-500 dark:text-gray-300">
        I am writting code to solve real-world problems and make the world a
        better place.
      </p>

      <div class="flex justify-center md:justify-start gap-2 mt-12">
        <router-link
          to="/about"
          class="flex justify-center items-center w-36 sm:w-48 mb-6 sm:mb-0 text-lg font-bold border border-accent py-2.5 sm:py-3 shadow-lg rounded-lg bg-accent focus:ring-1 focus:ring-white hover:bg-white hover:border-white text-white hover:text-accent duration-500"
          aria-label="About Me">
          <i
            data-feather="info"
            class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg duration-100">About Me</span>
        </router-link>

        <a
          @click="showPopup = true"
          class="flex justify-center items-center w-36 sm:w-48 mb-6 sm:mb-0 text-lg font-bold border border-white py-2.5 sm:py-3 shadow-lg rounded-lg bg-white focus:ring-1 focus:ring-accent hover:bg-accent hover:border-accent text-accent hover:text-white duration-500 cursor-pointer"
          aria-label="View Resume">
          <i
            data-feather="eye"
            class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg duration-100">View CV</span></a
        >
      </div>
    </div>

    <!-- Banner right illustration -->
    <div class="w-full md:w-1/2 flex justify-center md:justify-end">
      <img
        class="md:w-64 w-44 animate-[jackInTheBox_1s_ease-in-out]"
        v-if="themeState.theme === 'light'"
        src="@/assets/images/logo-dark.png"
        alt="Developer" />
      <img
        v-else
        class="md:w-64 w-44 animate-[jackInTheBox_1s_ease-in-out]"
        src="@/assets/images/logo-light.png"
        alt="Developer" />
    </div>

    <!-- Popup for Email Input -->
    <transition name="fade">
      <div
        v-if="showPopup"
        class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div
          class="bg-white dark:bg-ternary-dark p-8 rounded-lg shadow-lg w-96 relative animate-popup">
          <button
            @click="showPopup = false"
            id="closePopup"
            class="absolute top-2 right-2 text-gray-500 dark:text-gray-300 text-2xl">
            &times;
          </button>
          <h2
            class="text-xl font-bold mb-4 text-ternary-dark dark:text-ternary-light">
            Share Via Email
          </h2>
          <p class="mb-4 text-gray-700 dark:text-gray-300">
            Enter your email to receive the CV:
          </p>
          <input
            type="email"
            v-model="email"
            placeholder="Your email"
            class="w-full p-2 mb-4 border border-gray-300 rounded dark:bg-gray-800 dark:text-gray-300" />
          <button
            @click="shareViaEmail"
            class="w-full bg-accent text-white py-2 rounded-lg hover:bg-accent-dark duration-300 relative">
            <span
              v-if="isLoading">
              Sending...
            </span>
            <span v-else>Send</span>
          </button>
          <p v-if="successMessage" class="mt-4 font-bold text-accent">
            {{ successMessage }}
          </p>
        </div>
      </div>
    </transition>
  </section>
</template>

<script setup>
import { ref, onMounted, onUpdated } from "vue";
import feather from "feather-icons";
import { inject } from "vue";
import emailjs from "emailjs-com";

const hiArray = ref(["H", "i", ","]);
const nameArray = ref([
  "I",
  "'m",
  "&nbsp;",
  "A",
  "r",
  "i",
  "f",
  "&nbsp;",
  "I",
  "s",
  "l",
  "a",
  "m",
]);

const jobArray = ref([
  "<",
  "S",
  "o",
  "f",
  "t",
  "w",
  "a",
  "r",
  "e",
  "&nbsp;",
  "D",
  "e",
  "v",
  "e",
  "l",
  "o",
  "p",
  "e",
  "r",
  "/>",
]);

// Reactive state
const theme = ref("");
const showPopup = ref(false);
const email = ref("");
const successMessage = ref("");
const isLoading = ref(false);

// Equivalent to 'created' lifecycle hook
theme.value = localStorage.getItem("theme") || "light";
const themeState = inject("themeState");

onMounted(() => {
  feather.replace();
  theme.value = localStorage.getItem("theme") || "light";
});

onUpdated(() => {
  feather.replace();
});

const shareViaEmail = () => {
  if (email.value) {
    isLoading.value = true; // Start loading
    const templateParams = {
      from_email: email.value,
      message: "Here is the CV you requested!",
    };

    emailjs
      .send(
        "service_pltbxss",
        "template_mc56lsh",
        templateParams,
        "eYhzkfzvSFELv43Cy"
      )
      .then(
        (response) => {
          successMessage.value = `CV will be sent to ${email.value}`;
          setTimeout(() => {
            showPopup.value = false;
            email.value = "";
            successMessage.value = "";
            isLoading.value = false;
          }, 5000); 
        },
        (err) => {
          isLoading.value = false; 
        }
      );
  } else {
    alert("Please enter a valid email.");
  }
};
</script>

<style scoped>
/* Popup animation */
@keyframes popup {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-popup {
  animation: popup 0.3s ease-in-out;
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Close button styling */
button#closePopup {
  background: transparent;
  border: none;
  cursor: pointer;
}
button#closePopup:hover {
  color: red;
}
</style>
